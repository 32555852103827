<template>
  <div class="addposts">
    <van-nav-bar
      title="我的动态"
      right-text="发布"
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <div class="addposts-content">
      <textarea
        placeholder="想说点什么..."
        rows="4"
        class="mint-field-core"
        v-model="value"
      ></textarea>
    </div>
    <div class="addposts-imgwrap">
      <ul class="addposts-imgwrap-preview">
        <li
          class="addposts-imgwrap-preview-item"
          v-for="(imgItem, index) in succImg"
          :key="imgItem.id"
        >
          <div class="addposts-imgwrap-preview-item-img" @click="getImg(index)">
            <img :src="imgItem.picPath" alt="" />
          </div>
          <div
            class="addposts-imgwrap-preview-item-del"
            @click="delImg(index, imgItem.id)"
          >
            <i class="van-icon van-icon-cross"></i>
          </div>
        </li>
        <!-- <li class="addposts-imgwrap-preview-item">
          <div class="addposts-imgwrap-preview-item-img">
            <img src="" alt="" />
          </div>
          <div class="addposts-imgwrap-preview-item-del">
            <i class="van-icon van-icon-cross"></i>
          </div>
        </li> -->
      </ul>
      <van-uploader :max-count="3" :after-read="afterRead" />
    </div>
  </div>
</template>

<script>
import { uploadImg, sendPost, delUploadImg } from "@api/community.js";
import { ImagePreview } from "vant";
import qs from "qs";
export default {
  name: "AddPosts",
  data() {
    return {
      value: "",
      // 存储上传的图片
      succImg: [],
    };
  },
  computed: {
    imgList() {
      return this.succImg.map((item) => {
        return item.picPath;
      });
    },
  },
  methods: {
    getImg(index) {
      ImagePreview({
        images: this.imgList,
        showIndex: true,
        startPosition: index,
      });
    },
    onClickRight() {
      // 内容不能为空
      if (this.value === "") {
        return;
      }
      this.uploadImg();
    },
    async afterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      const result = await uploadImg(param);
      if (result.code === 0) {
        this.succImg.push({ ...result.data });
      } else {
        this.$toast(result.msg);
      }
    },
    async uploadImg() {
      // 上传图片  判断当前的图片是单张还是多张
      let picIds = [];
      this.succImg.forEach((item) => {
        picIds.push(item.id);
      });
      const requestParam = {
        content: this.value,
        picIds,
      };

      const result = await sendPost(requestParam);
      if (result.code === 0) {
        this.value = "";
        this.succImg.length = 0;
        console.log(this.succImg.length);
        if (this.value === "" && this.succImg.length === 0) {
          this.$router.replace("/home/Community");
        }
      }
    },
    onClickLeft() {
      this.$router.replace("/home/Community");
    },
    delImg(index, id) {
      // 删除指定下标的图片对象
      if (isNaN(index) || index >= this.succImg.length) {
        return false;
      }
      let tmp = [];
      for (let i = 0, len = this.succImg.length; i < len; i++) {
        if (this.succImg[i] !== this.succImg[index]) {
          tmp.push(this.succImg[i]);
        }
      }
      this.succImg = tmp;
      this.deleteImg(id);
    },
    async deleteImg(id) {
      const requestParam = {
        picIds: id,
      };
      const result = await delUploadImg(qs.stringify(requestParam));
      return Promise.resolve(result);
    },
  },
  // 组件离开时的校验  是否还有内容未发布
  beforeRouteLeave(to, from, next) {
    console.log(to, from);
    if (this.value === "" && this.imgList.length === 0) {
      next();
    } else {
      this.$dialog
        .alert({
          message: "您还有内容未发布，是否要退出？",
          showConfirmButton: true,
          showCancelButton: true,
        })
        .then(async () => {
          // 点击确定的情况下
          // 存在图图片的情况下
          if (this.succImg.length !== 0) {
            const ids = this.succImg.map((item) => item.id).join(",");
            const result = await this.deleteImg(ids);
            if (result.code === 0) {
              this.value = "";
              next();
            } else {
              this.$toast(result.msg);
            }
            // 没有图片则清空文本即可
          } else {
            this.value = "";
            next();
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
// 改变vantui的默认样式
::v-deep .van-nav-bar__text {
  font-weight: bold;
  font-size: 17px;
  color: #333;
}
::v-deep .van-nav-bar__arrow {
  color: #333;
}
::v-deep .van-nav-bar__title {
  font-weight: bold;
  font-size: 17px;
  color: #333;
}
.addposts-content {
  position: relative;
  padding: 20px 26px;
  font-size: 15px;
  margin-top: 10px;
  textarea {
    width: 100%;
  }
  &::before {
    position: absolute;
    display: block;
    top: -10px;
    left: 0;
    content: " ";
    width: 375px;
    height: 10px;
    background: #f4f4f4;
  }
}
.addposts-imgwrap {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  &-preview {
    display: flex;
    flex-wrap: wrap;
    &-item {
      position: relative;
      overflow: hidden;
      margin: 0 8px 8px 0;
      &-img {
        display: block;
        width: 80px;
        height: 80px;
        // background-color: #f01414;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          overflow: hidden;
        }
      }
      &-del {
        position: absolute;
        top: 0;
        right: 0;
        width: 14px;
        height: 14px;
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: 0 0 0 12px;
        i {
          position: absolute;
          top: -2px;
          right: -2px;
          color: #fff;
          font-size: 16px;
          -webkit-transform: scale(0.5);
          transform: scale(0.5);
        }
      }
    }
  }
}
</style>
